import imageMapResize from 'image-map-resizer';

imageMapResize();

/**
 * Zatvorenie výsuvnej karty mapy.
 */
document.querySelectorAll('.es-map-card').forEach((card) => {
    const closeBtn = card.querySelector('button');

    closeBtn.addEventListener('click', () => {
        card.classList.remove('show');
    });
});

let currentlyOpenCard = null;

/**
 * Zobrazenie výsuvnej karty po kliku na area v image mape.
 */
document.querySelectorAll('.es-operational-centers area').forEach((area) => {
    area.addEventListener('click', (evt) => {
        evt.preventDefault();

        if (currentlyOpenCard) {
            currentlyOpenCard.classList.remove('show');
        }
        const card = document.querySelector(`[data-location="${area.target}"`);
        card.classList.add('show');
        currentlyOpenCard = card;
    });
});
